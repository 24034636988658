<template>
  <div class="maintenance-page">
    <div class="container container--pv">
      <h1 class="f-500 f-size-54 s-mb-4">{{ $t('pages.maintenance.title') }}</h1>

      <h3
        class="subtitle f-300 f-size-24 container-flex container-flex--align-center container-flex--justify-center s-gap-8"
      >
        <Icon class="icon" name="build" />
        {{ $t('pages.maintenance.subtitle') }}
      </h3>

      <p class="description text-secondary medium size-18">
        {{ $t('pages.maintenance.description')[0] }} <br />
        {{ $t('pages.maintenance.description')[1] }}
      </p>

      <div class="contact-info">
        <!-- <span class="f-as-link f-as-link--with-icon" @click="() => openChat()">
          <Icon name="chat" />
          <span>
            {{ $t('global.contact_through_chat') }}
          </span>
        </span> -->

        <a class="f-as-link f-as-link--with-icon" :href="`mailto:${contactEmail}`">
          <Icon name="email" />
          <span>
            {{ contactEmail }}
          </span>
        </a>

        <!-- <a class="f-as-link f-as-link--with-icon" :href="`tel:${contactPhone}`">
          <Icon name="phone" />
          <span>
            {{ contactPhone }}
          </span>
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Icon from '@/components/Icon.vue'

export default {
  name: 'PagesMaintenance',
  layout: 'empty',
  components: {
    Icon,
  },
  data() {
    return {
      contactPhone: process.env.contactPhone,
      contactEmail: process.env.contactEmail,
    }
  },
  methods: {
    openChat() {
      const chat = document.querySelector('.chatwith-mainbutton')

      if (chat && !chat.classList.contains('without-text')) {
        chat.click()
      }
    },
  },
  mounted() {
    this.$tracker.pageView('maintenance', this.$t('maintenance.head.title'))
  },
  head() {
    return this.$generatePageMetasFromKey('pages.maintenance', false)
  },
}
</script>

<style lang="scss" scoped>
.maintenance-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url('/images/maintenance-bg.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  .container {
    @include size-s-up {
      text-align: center;
    }
  }

  .contact-info {
    display: inline-flex;
    align-items: center;
    margin-top: 2.5rem;

    @include size-xs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1.5rem;
    }

    .f-as-link {
      margin: 0 0.375rem 0 0;

      &:not(:first-child) {
        &::before {
          content: ' | ';
          margin: 0 1rem;
          color: $text-secondary;

          @include size-xs {
            display: none;
          }
        }

        @include size-xs {
          margin-top: 1.5rem;
        }
      }

      @include size-xs {
        flex-direction: row;
      }
    }
  }
}
</style>
